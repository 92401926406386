<template>
  <div>
    <div class="card mt-2 shadow-sm">
      <div
        class="
          card-header
          border-0
          d-flex
          justify-content-between
          align-items-center
        "
        @click="visibleDetail = !visibleDetail"
      >
        結帳明細
        <i
          class="fa"
          :class="`fa-chevron-${visibleDetail ? 'up' : 'down'}`"
          aria-hidden="true"
        ></i>
      </div>
      <b-collapse v-model="visibleDetail" class="products">
        <div class="products__item p-3" v-for="p in products" :key="p.name">
          <div class="products__item__photo product__photo">
            <img
              :src="`https://picsum.photos/200?${Math.random()}`"
              class="img-fluid"
            />
          </div>
          <div
            class="
              products__item__title
              product__title
              d-inline-block
              text-truncate
            "
          >
            {{ p.title }}
          </div>
          <div
            class="
              products__item__price
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div>
              <span v-if="p.oprice" class="oprice mr-1">{{ p.oprice }}</span>
              <span class="price">{{ p.price }}</span>
            </div>
            <div class="small">x1</div>
          </div>
        </div>

        <div class="p-3 detail">
          <div class="d-flex justify-content-between">
            <span>訂單金額</span>
            <span class="text-dark">$ 4,160</span>
          </div>

          <div class="d-flex justify-content-between">
            <span>運費</span>
            <span class="text-primary">免運費</span>
          </div>
        </div>

        <div class="d-flex justify-content-between card-header border-0">
          <span>總金額</span>
          <span class="price">NT$ 3,560</span>
        </div>
      </b-collapse>
    </div>

    <div class="card mt-2 shadow-sm">
      <div
        class="
          card-header
          border-0
          d-flex
          justify-content-between
          align-items-center
        "
        @click="visiblePurchaser = !visiblePurchaser"
      >
        訂購人資訊
        <i
          class="fa"
          :class="`fa-chevron-${visiblePurchaser ? 'up' : 'down'}`"
          aria-hidden="true"
        ></i>
      </div>
      <b-collapse v-model="visiblePurchaser">
        <div class="p-3 detail">
          <div>黃*彬</div>
          <div>新北市永和區永和路一段***號*樓</div>
          <div>0911***450</div>
        </div>
      </b-collapse>
    </div>

    <div class="card mt-2 shadow-sm">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        收件人資訊
      </div>
      <div class="p-3">
        <b-form-group>
          <b-form-radio
            v-model="recipientType"
            value="1"
            class="py-2 font-weight-bold"
            >同訂購人</b-form-radio
          >
          <b-form-radio v-model="recipientType" value="2" class="py-2">
            <div class="font-weight-bold">填寫收件人資料</div>

            <div class="py-3 row">
              <div class="col-12 mb-3">
                <label for="recipient-name"
                  >收件人姓名<span class="text-primary">*</span></label
                >
                <input
                  type="text"
                  id="recipient-name"
                  placeholder="請輸入收件人姓名"
                  class="field__input"
                />
              </div>

              <div class="col-6 mb-3">
                <label for="recipient-city"
                  >縣市<span class="text-primary">*</span></label
                >

                <select class="field__input">
                  <option>請選擇</option>
                </select>
              </div>
              <div class="col-6 mb-3">
                <label for="recipient-dist"
                  >鄉鎮市區<span class="text-primary">*</span></label
                >
                <select class="field__input">
                  <option>請選擇</option>
                </select>
              </div>

              <div class="col-12 mb-3">
                <label for="recipient-addr"
                  >地址<span class="text-primary">*</span></label
                >
                <input
                  type="text"
                  id="recipient-addr"
                  placeholder="請輸入地址"
                  class="field__input"
                />
              </div>

              <div class="col-12">
                <label for="recipient-phone"
                  >收件人電話<span class="text-primary">*</span></label
                >
                <input
                  type="text"
                  id="recipient-phone"
                  placeholder="請輸入收件人電話"
                  class="field__input"
                />
              </div>
            </div>
          </b-form-radio>
        </b-form-group>
      </div>
    </div>

    <div class="card mt-2 shadow-sm">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        付款方式
      </div>
      <div class="p-3">
        <b-form-group>
          <b-form-radio v-model="paymentType" value="1" class="py-2">
            <div class="font-weight-bold">信用卡一次付清</div>

            <div class="py-3 row">
              <div class="col-12 mb-3">
                <label for="card-number"
                  >信用卡卡號<span class="text-primary">*</span></label
                >
                <input
                  type="text"
                  id="card-number"
                  placeholder="請輸入信用卡卡號"
                  class="field__input"
                />
              </div>

              <div class="col-4">
                <label for="card-expired-month"
                  >有效月<span class="text-primary">*</span></label
                >
                <select class="field__input">
                  <option>月</option>
                </select>
              </div>
              <div class="col-4">
                <label for="card-expired-year"
                  >有效年<span class="text-primary">*</span></label
                >
                <select class="field__input">
                  <option>年</option>
                </select>
              </div>

              <div class="col-4">
                <label for="card-cvv"
                  >驗證碼<span class="text-primary">*</span></label
                >
                <input
                  type="text"
                  id="card-cvv"
                  placeholder="驗證碼"
                  class="field__input"
                />
              </div>

              <div class="col-12">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="card-remember"
                  />
                  <label class="form-check-label" for="card-remember">
                    我同意紀錄本次付款資訊
                  </label>
                </div>
              </div>
            </div>
          </b-form-radio>
          <b-form-radio
            v-model="paymentType"
            value="2"
            class="py-2 font-weight-bold"
            >信用卡分期付款</b-form-radio
          >
          <b-form-radio
            v-model="paymentType"
            value="3"
            class="py-2 font-weight-bold"
            >ATM轉帳</b-form-radio
          >
        </b-form-group>
      </div>
    </div>

    <div class="card mt-2 shadow-sm">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        發票資訊
      </div>
      <div class="p-3">
        <div class="tab">
          <nav class="nav justify-content-around">
            <a
              class="flex-fill text-sm-center nav-link text-center"
              :class="invoiceTab == 1 && 'active'"
              @click="invoiceTab = 1"
              role="button"
              >個人</a
            >
            <a
              class="flex-fill text-sm-center nav-link text-center"
              :class="invoiceTab == 2 && 'active'"
              @click="invoiceTab = 2"
              role="button"
              >公司</a
            >
          </nav>

          <div class="p-3">
            <div v-if="invoiceTab == 1">
              <b-form-group>
                <b-form-radio v-model="invoiceCarrier" value="1" class="py-2">
                  <div class="font-weight-bold">手機條碼載具</div>

                  <div class="py-3 row">
                    <div class="col-12">
                      <input
                        type="text"
                        placeholder="請輸入/後大寫7個英數字"
                        class="field__input"
                      />
                    </div>

                    <div class="col-12">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="invoice-remember"
                        />
                        <label class="form-check-label" for="invoice-remember">
                          我同意紀錄本次付款資訊
                        </label>
                      </div>
                    </div>
                  </div>
                </b-form-radio>
                <b-form-radio
                  v-model="invoiceCarrier"
                  value="2"
                  class="py-2 font-weight-bold"
                >
                  <div class="font-weight-bold">自然人憑證條碼載具</div>

                  <div class="py-3 row">
                    <div class="col-12">
                      <input
                        type="text"
                        placeholder="請輸入自然人憑證"
                        class="field__input"
                      />
                    </div>

                    <div class="col-12">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="invoice-remember"
                        />
                        <label class="form-check-label" for="invoice-remember">
                          我同意紀錄本次付款資訊
                        </label>
                      </div>
                    </div>
                  </div>
                </b-form-radio>
              </b-form-group>
            </div>
            <div v-else-if="invoiceTab == 2">
              <b-form-group>
                <b-form-radio v-model="invoiceCarrier" value="3" class="py-2">
                  <div class="font-weight-bold">公司發票</div>

                  <div class="py-3 row">
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        placeholder="請輸入統一編號"
                        class="field__input"
                      />
                    </div>

                    <div class="col-12">
                      <input
                        type="text"
                        placeholder="請輸入抬頭"
                        class="field__input"
                      />
                    </div>

                    <div class="col-12">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="invoice-remember"
                        />
                        <label class="form-check-label" for="invoice-remember">
                          我同意紀錄本次付款資訊
                        </label>
                      </div>
                    </div>
                  </div>
                </b-form-radio>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sticky-bottom">
      <div class="border-bottom small px-3 py-2 border-top">
        結帳即同意及閱讀<a href="#" >隱私權條款</a>與<a href="#">購買須知</a>。
      </div>
      <div class="d-flex justify-content-between align-items-center p-3">
        <div>
          <div class="font-weight-bold">總金額</div>
          <div class="price">$ 3,560</div>
        </div>

        <div class="flex-fill pl-3">
          <router-link
            class="btn w-100 btn-primary btn-lg"
            :to="{ name: 'LiffShopFinished' }"
          >
            確認結帳
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import themeColor from "@/mixins/liff/themeColor";

const products = [
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
    quantity: 1,
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
    quantity: 1,
  },
];

export default {
  mixins: [themeColor],
  data() {
    return {
      products,
      visibleDetail: true,
      visiblePurchaser: true,
      recipientType: 1,
      paymentType: 1,
      invoiceTab: 1,
      invoiceCarrier: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
.card {
  &-header {
    font-weight: 700;
    font-size: 17px;
  }
}

.products {
  &__item {
    color: #212121;
    text-decoration: none !important;
    display: grid;
    grid-template-columns: 56px auto;
    grid-template-rows: min-content max-content;
    gap: 0px 1rem;
    grid-template-areas:
      "photo title"
      "photo price";

    border-top: solid 1px #eee;

    &__photo {
      grid-area: photo;
    }
    &__title {
      grid-area: title;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    &__price {
      grid-area: price;
    }

    .b-form-spinbutton {
      border: none;
      text-align: center;
      width: auto;
      height: auto;
      padding: 0;

      ::v-deep .btn {
        background: #e7edf6;
        padding: 5px !important;
        border-radius: 8px !important;
      }

      ::v-deep output {
        text-align: center !important;
        padding: 0.5rem 1rem;
        border: none !important;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}

.detail {
  font-size: 14px;

  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1050;

  box-shadow: 0px -2px 4px 0px #0000000d;
}
</style>
